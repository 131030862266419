import React from "react";

const Body7 = (props) => {
  return (
    <div className="flex items-center text-center justify-center" {...props}>
      <p className="text-black text-center text-[39px] font-black tracking-wide mt-[104px] mb-[34px]
      tablet:mt-[280px] tablet:text-[60px]
      desktop:mt-[280px] mx-16">
      SEJA UM <span className="text-mediumGreen">PARCEIRO</span> DA AGRORECUPERA
      </p>
      <div>
        <p  className=" text-[18px] font-light tracking-wide text-lettertext text-center leading-10
        tablet:text-[23px] mx-16 tablet:mx-32">
            <span className="font-bold">Para você Contador, Advogado ou Parceiro de produtores rurais! </span>
            Temos uma proposta de parceria que pode atender as necessidades do seu cliente. Junte-se a nós como um parceiro da AgroRecupera.<br/>
            Nossa equipe especializada irá auxiliar em todo processo, proporcionando mais eficiência, segurança e rentabilidade para seus clientes.
        </p>
      </div>
    </div>
  );
};

export default Body7;
