import React from "react";

const DiamondIcon = () => (
  <>
    <svg width="67" height="67" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="33.6045" cy="33.3928" r="33.0193" fill="#FAFAFA"/>
      <path d="M50.5372 30.0427C51.1259 30.0427 51.4879 29.4072 51.1826 28.9102L46.7268 21.6628C46.4796 21.2583 46.0298 21.0105 45.5537 21.0105H38.9396C38.4069 21.0105 38.042 21.541 38.2375 22.0303L41.2567 29.5717C41.3707 29.8558 41.6491 30.0434 41.9587 30.0434H50.5386L50.5372 30.0427Z" fill="#55641F"/>
      <path d="M29.008 22.0324C29.2057 21.5424 28.8408 21.0105 28.3074 21.0105H22.0588C21.3532 21.0105 20.7001 21.3555 20.2623 22.0079L16.0262 28.9109C15.7209 29.4079 16.0836 30.0434 16.6715 30.0434H25.2621C25.5709 30.0434 25.8486 29.8572 25.9627 29.5744L29.0094 22.033L29.008 22.0324Z" fill="#55641F"/>
      <path d="M39.8399 29.0229L36.8194 21.4815C36.7053 21.1974 36.4269 21.0105 36.1174 21.0105H31.1353C30.8264 21.0105 30.5488 21.1967 30.4347 21.4794L27.3879 29.0208C27.1903 29.5108 27.5551 30.0427 28.0885 30.0427H39.1372C39.6699 30.0427 40.0347 29.5122 39.8392 29.0229H39.8399Z" fill="#55641F"/>
      <path d="M41.358 32.08L35.6711 49.842C35.4154 50.6406 36.4808 51.1845 36.9922 50.5168L50.6045 32.7547C50.9807 32.2634 50.6265 31.5593 50.0031 31.5593H42.0777C41.7483 31.5593 41.4572 31.77 41.358 32.08Z" fill="#55641F"/>
      <path d="M27.1138 32.5301L32.8871 50.5608C33.1096 51.2551 34.1041 51.2551 34.3259 50.5608L40.0978 32.5301C40.2515 32.0493 39.8881 31.5593 39.3781 31.5593H27.8328C27.3228 31.5593 26.9594 32.0493 27.1131 32.5301H27.1138Z" fill="#55641F"/>
      <path d="M16.6092 32.7547L30.2187 50.5168C30.7301 51.1845 31.7955 50.6399 31.5398 49.842L25.855 32.08C25.7559 31.77 25.4647 31.5593 25.1353 31.5593H17.2099C16.5865 31.5593 16.2316 32.2641 16.6085 32.7547H16.6092Z" fill="#55641F"/>
    </svg>
  </>
  
  );

export default DiamondIcon;

