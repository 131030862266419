import React from "react";

const PlantBottleIcon = () => (
  <>
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20.0679" cy="20.7549" r="19.7661" fill="#8DA633"/>
      <g clip-path="url(#clip0_0_1)">
      <path d="M25.4852 29.9977L23.1259 23.1192L24.1405 21.165H16.0116L17.0262 23.1192L14.6265 29.9977C14.3004 30.9357 15.0332 31.9128 16.0478 31.9128H24.0599C25.0786 31.9128 25.8073 30.9357 25.4852 29.9977Z" fill="#BBCA85"/>
      <path d="M24.1445 19.2109H16.0116C15.7257 19.2109 15.4841 19.3282 15.2788 19.4845C15.0775 19.6408 14.9929 19.9144 14.9929 20.188C14.9929 20.7352 15.4398 21.1651 16.0075 21.1651H24.1405C24.4263 21.1651 24.6679 21.0478 24.8732 20.8915C25.0786 20.6961 25.1591 20.4616 25.1591 20.188C25.1591 19.6408 24.6719 19.2109 24.1445 19.2109Z" fill="white"/>
      <path d="M20.0776 17.1787C20.2387 16.3189 20.2387 15.42 20.0776 14.5992C20.3232 13.4267 20.8507 12.3715 21.7445 11.4726C23.2503 9.94838 25.4043 11.0818 27.5986 9.6748C27.965 11.7462 27.4375 13.8567 25.9317 15.3809C24.4259 16.866 22.2719 17.4523 20.0776 17.1787Z" fill="#BBCA85"/>
      <path d="M20.0778 14.5602C20.2388 15.3809 20.2388 16.2798 20.0778 17.1396C17.8835 17.4132 15.7255 16.827 14.2237 15.3418C12.7179 13.8176 12.1905 11.7071 12.5528 9.63574C14.5458 11.1991 16.9051 9.9484 18.4069 11.4335C19.3007 12.3325 19.8282 13.4268 20.0738 14.5602H20.0778Z" fill="#BBCA85"/>
      <path d="M24.0639 32.3821H16.0518C15.3995 32.3821 14.7916 32.0694 14.4252 31.5613C14.0588 31.0532 13.938 30.4279 14.1393 29.8026L16.4584 23.1194L15.5243 21.3607C15.4036 21.1262 15.4841 20.8136 15.7699 20.6963C16.0155 20.5791 16.3376 20.6572 16.4624 20.9308L17.477 22.885C17.5173 23.0022 17.5576 23.1194 17.5173 23.2758L15.1177 30.1543C14.9969 30.467 15.0774 30.7797 15.2385 31.0142C15.4398 31.2487 15.7256 31.405 16.0518 31.405H24.0639C24.3901 31.405 24.6719 31.2487 24.8772 31.0142C25.0826 30.7797 25.1228 30.4279 24.998 30.1543L22.5984 23.2758C22.5581 23.1585 22.5581 23.0022 22.6387 22.885L23.6573 20.9308C23.7781 20.6963 24.1042 20.5791 24.3498 20.6963C24.5954 20.8136 24.7162 21.1262 24.5954 21.3607L23.6613 23.1194L25.9804 29.8026C26.1817 30.3888 26.1012 31.0532 25.6945 31.5613C25.3282 32.1085 24.7202 32.3821 24.068 32.3821H24.0639Z" fill="#FAFAFA"/>
      <path d="M24.1443 21.6344H16.0114C15.1578 21.6344 14.4653 20.9699 14.4653 20.1492C14.4653 19.7584 14.6264 19.4066 14.9122 19.094C15.1981 18.8204 15.6047 18.6641 16.0114 18.6641H24.1443C24.9979 18.6641 25.6904 19.3285 25.6904 20.1492C25.6904 20.54 25.5294 20.8918 25.2435 21.2044C24.9576 21.5171 24.551 21.6344 24.1443 21.6344ZM16.0114 19.6802C15.8906 19.6802 15.7658 19.7193 15.645 19.8365C15.5645 19.9147 15.484 20.032 15.484 20.1883C15.484 20.4619 15.7296 20.6964 16.0114 20.6964H24.1443C24.2651 20.6964 24.3899 20.6573 24.5107 20.54C24.5913 20.4619 24.6718 20.3446 24.6718 20.1883C24.6718 19.9147 24.4262 19.6802 24.1443 19.6802H16.0114Z" fill="#FAFAFA"/>
      <path d="M21.1327 17.7257C20.7663 17.7257 20.3999 17.6866 20.0336 17.6475H19.953C19.7074 17.6085 19.5866 17.4131 19.5464 17.1786C19.5464 16.9441 19.7074 16.7486 19.9128 16.7096C19.9933 16.6705 20.0738 16.6705 20.1584 16.7096C22.2319 16.9831 24.1846 16.3578 25.5253 15.029C26.705 13.8565 27.2727 12.215 27.1519 10.4563C26.2178 10.8862 25.324 10.9644 24.5107 11.0426C23.4921 11.1207 22.7231 11.1989 22.0708 11.8633C21.2978 12.645 20.7703 13.622 20.5247 14.7163C20.4845 14.9899 20.1986 15.1463 19.9128 15.1072C19.6269 15.0681 19.4659 14.7945 19.5061 14.5209C19.7517 13.2312 20.3999 12.0587 21.2938 11.1598C22.1876 10.2609 23.327 10.1437 24.3859 10.0655C25.32 9.98733 26.2983 9.90916 27.2727 9.28384C27.4337 9.20567 27.5988 9.16659 27.7598 9.24476C27.9209 9.32292 28.0457 9.44017 28.0457 9.5965C28.5329 11.9024 27.8847 14.1301 26.2983 15.6934C24.9979 17.0222 23.1659 17.7257 21.1327 17.7257Z" fill="#FAFAFA"/>
      <path d="M20.0777 27.1055C20.0509 27.1055 20.0509 27.1055 20.0777 27.1055C19.7919 27.1055 19.5503 26.871 19.5503 26.5974L19.5906 17.1394C19.5906 16.8658 19.8362 16.6704 20.118 16.6704C20.4038 16.6704 20.6052 16.9049 20.6052 17.1394L20.5649 26.6365C20.5649 26.9101 20.3193 27.1055 20.0777 27.1055Z" fill="#FAFAFA"/>
      <path d="M20.0779 17.6476C19.9571 17.6476 19.8323 17.6086 19.7115 17.5304C19.5102 17.335 19.5102 17.0223 19.7115 16.8269L23.7377 12.7232C23.9391 12.5278 24.2652 12.5278 24.4705 12.7232C24.6758 12.9186 24.6718 13.2313 24.4705 13.4267L20.4443 17.5304C20.3638 17.6086 20.243 17.6476 20.0779 17.6476Z" fill="#FAFAFA"/>
      <path d="M19.019 17.7258C16.9857 17.7258 15.1578 17.0223 13.8534 15.6935C12.2711 14.1302 11.6188 11.9024 12.0657 9.55747C12.106 9.40114 12.2268 9.24481 12.3918 9.16664C12.5529 9.08848 12.7582 9.12756 12.879 9.24481C13.7728 9.9483 14.7069 9.98738 15.7255 10.0655C16.7844 10.1437 17.9198 10.2219 18.8177 11.0817C19.7518 11.9806 20.3637 13.1531 20.6053 14.4428C20.7664 15.3417 20.7664 16.2797 20.6053 17.2177C20.565 17.4131 20.404 17.5694 20.1584 17.6085C19.792 17.6867 19.3854 17.7258 19.019 17.7258ZM12.9595 10.4955C12.8387 12.2151 13.4064 13.8566 14.5861 15.0291C15.7255 16.1625 17.2716 16.7487 19.019 16.7487C19.2203 16.7487 19.4256 16.7487 19.6269 16.7096C19.7075 16.0061 19.7075 15.3026 19.5464 14.6382C19.3411 13.583 18.8136 12.5668 18.0406 11.7852C17.4286 11.1599 16.5751 11.1208 15.641 11.0426C14.7874 11.0035 13.8534 10.9644 12.9555 10.4955H12.9595Z" fill="#FAFAFA"/>
      <path d="M20.0778 17.6478C19.957 17.6478 19.8322 17.6087 19.7517 17.5305L15.645 13.9349C15.4437 13.7395 15.4437 13.4269 15.6047 13.2314C15.806 13.036 16.1321 13.036 16.3375 13.1924L20.4442 16.788C20.6455 16.9834 20.6455 17.2961 20.4845 17.4915C20.3637 17.6087 20.2389 17.6478 20.0778 17.6478Z" fill="#FAFAFA"/>
      </g>
      <defs>
      <clipPath id="clip0_0_1">
      <rect width="16.278" height="23.2542" fill="white" transform="translate(11.9287 9.12793)"/>
      </clipPath>
      </defs>
    </svg>
  </>
);

export default PlantBottleIcon;

