import React from 'react';

const MapEmbed = () => {
  return (
    <div style={{ width: '100%', display: 'flex', alignItems:"center", justifyContent:"center" }}>
      <iframe
        title="Google Maps"
        width="100%"
        height="320"
        src="https://maps.google.com/maps?width=100%25&amp;height=320&amp;hl=pt&amp;q=R.%20Eucl%C3%ADdes%20da%20Cunha,%201091%20Vila%20Bandeirantes,%20Ara%C3%A7atuba%20-%20SP+(Agrorecupera)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
      >
      </iframe>
    </div>
  );
};

export default MapEmbed;
