import React from "react";
import MapEmbed from "./map";

const Body8 = (props) => {

  const openInNewTab = () => {
    const fullUrl = "https://wa.me/5518998085084";
    const newWindow = window.open(fullUrl, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };


  return (
    <div className="flex items-center text-center justify-center" id="contato" {...props}>
      <div className="bg-lightBrown min-h-screen pt-[10px] px-16 pb-[50px] flex flex-col items-center">
        <p className="text-white text-center text-[29px] font-black tracking-wide mt-[50px] mb-[34px]
        tablet:text-[60px]">
        ENTRE EM CONTATO
        </p>
        <div>
          <p  className=" text-[17px] font-light tracking-wide text-white text-center leading-10 mx-8
          tablet:text-[23px]">
            Estamos <span className="font-black">ansiosos</span> para ouvir de você e teremos o prazer de ajudá-lo.<br/>
            <span className="font-black">Entre em contato!</span>  
          </p>
        </div>
        <div>
          <p  className=" text-[17px] font-light tracking-wide text-white text-left leading-10 mt-[20px] mb-[40px] mx-8
          tablet:text-center tablet:text-[23px]">
            <span className="font-black">Telefone:</span> +55 18 99808 5084 <br/>
            <span className="font-black">Email:</span><a href="mailto:agrorecupera@ksuassessoria.com.br"> agrorecupera@ksuassessoria.com.br</a><br/>
            <span className="font-black">Localização:</span> R. Euclídes da Cunha, 1091,
            Vila Bandeirantes, Araçatuba - SP  
          </p>
        </div>
        <MapEmbed />
        <button className=" flex justify-start items-center bg-white rounded-[17px] hover:bg-lightGreen hover:shadow-lg h-[60px] w-[280px] mt-[50px] 
            tablet:w-[290px] tablet:mx-[32px]
            desktop:mx-0
            text-lightBrown hover:text-white
            "
            onClick={openInNewTab}>
        
            <svg width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg" className="scale-[0.5]">
                <circle cx="42.5" cy="42.5" r="42.5" fill="#8DA633"/>
                <g clip-path="url(#clip0_1138_1223)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M44 19C31.8682 19 22 28.8682 22 41C22 45.2601 23.2081 49.3676 25.5098 52.9156L22.178 60.2786C21.8728 60.9526 21.9873 61.7538 22.4705 62.3133C22.8393 62.7457 23.3734 62.9873 23.9329 62.9873C24.0983 62.9873 24.2763 62.9619 24.4416 62.9237L33.6486 60.3931C36.8277 62.0971 40.3884 62.9873 44.0127 62.9873C56.1445 62.9873 66.0127 53.1191 66.0127 40.9873C66.0127 28.8555 56.1318 19 44 19ZM44 59.1595C40.7827 59.1595 37.6289 58.3075 34.8566 56.6925C34.5642 56.5145 34.2208 56.4254 33.8902 56.4254C33.7249 56.4254 33.5468 56.4509 33.3815 56.489L27.3665 58.1422L29.4647 53.526C29.7445 52.9029 29.6809 52.1653 29.274 51.6058C27.0358 48.5029 25.8532 44.8277 25.8532 41C25.8532 30.9919 33.9919 22.8405 44.0127 22.8405C54.0335 22.8405 62.1723 30.9792 62.1723 41C62.1723 51.0208 54.0335 59.1595 44.0127 59.1595H44Z" fill="#FAFAFA"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M52.4568 44.2936C51.5412 43.7468 50.3331 43.1237 49.2522 43.5688C48.4256 43.9121 47.8915 45.2092 47.3447 45.8832C47.0649 46.2266 46.7343 46.2774 46.3146 46.1121C43.1863 44.8659 40.7956 42.7803 39.0661 39.9063C38.7736 39.4613 38.8245 39.1052 39.1805 38.6982C39.7019 38.0878 40.3505 37.3884 40.4904 36.5618C40.6302 35.7352 40.2487 34.7688 39.9054 34.0312C39.473 33.0902 38.9771 31.7549 38.036 31.2208C37.1713 30.7375 36.0268 31.0046 35.251 31.6277C33.9158 32.7086 33.28 34.4127 33.2927 36.0913C33.2927 36.5745 33.3563 37.0451 33.4707 37.5156C33.7378 38.6219 34.2591 39.6647 34.8314 40.6566C35.2638 41.3942 35.747 42.1191 36.2557 42.8185C37.9216 45.0948 40.0071 47.0659 42.4106 48.541C43.6187 49.2786 44.9158 49.9271 46.251 50.3722C47.7516 50.8682 49.0996 51.3896 50.7274 51.0844C52.4314 50.7665 54.11 49.711 54.784 48.0705C54.9875 47.5873 55.0892 47.0404 54.9748 46.5318C54.7458 45.4763 53.3089 44.8404 52.4568 44.3318V44.2936Z" fill="#FAFAFA"/>
                </g>
                <defs>
                <clipPath id="clip0_1138_1223">
                <rect width="44" height="44" fill="white" transform="translate(22 19)"/>
                </clipPath>
                </defs>
            </svg>
            <p className=" font-extrabold text-16px">Chame no WhatsApp</p>
        </button>
      </div>
    </div>
  );
};

export default Body8;
