import React from "react";
import earthBG1 from '../assets/Rectangle19.png'
import SideButtons from "./side_button";
import SideButtonsHover from "./side_button_hover";


const Body = (props) => {
    return (
        <div className="relative z-0 object-fill h-[1500px] pt-[112px] 
        tablet:mt-[300px] tablet:mx-[34px] tablet:rounded-[26px] tablet:pt-0 tablet:h-[1800px]
        desktop:h-auto
        " id="quemSomos"
        style={{backgroundImage: `url(${earthBG1})`}}>
          <div className="bg-black bg-opacity-80 h-[1078px] pb-[88px]
          tablet:mx-[109px] tablet:h-full
          desktop:ml-[102px] desktop:mr-[277px]">
            <div
              className="text-center pt-[95px]
              desktop:pl-[48px] desktop:text-left"
            >
              <span className=" uppercase text-31px leading-[50px] p-0
              mobileXl:text-[35px]
              tablet:text-[64px] tablet:leading-[100px]
              ">
                <span className="text-white font-black">
                  Conheça a<br />
                </span>
                <span className="text-mediumGreen">
                  <span className="font-black pointer-events-none">AGRO</span>
                  RECUPERA</span>
              </span>
              <div className="flex justify-center items-center">
                <p className='text-white text-[17px] px-16 font-bold mt-[56px] leading-[36px]
                tablet:text-[23px] tablet:mx-[50px]
                desktop:pl-0 desktop:pr-[267px] desktop:m-0'>
                   Uma empresa formada por profissionais especializados
                  <span className='font-extralight'> com mais de </span>
                  20 anos de experiência em recuperação de créditos tributários para o setor Agro.
                  <span className='font-extralight'> Assessoramos produtores rurais na estruturação de suas operações para realizar recuperação de créditos tributários de ICMS de acordo com a legislação vigente.
                  </span> 
                </p>
                <div className=" absolute w-full right-0 hidden desktop:block">
                  <SideButtonsHover/>
                </div>
              </div>
            </div>
            <div className=" absolute w-full right-0 mt-[40px] desktop:hidden">
              <SideButtons/>
            </div>
          </div>
        </div>
    )
}

export default Body