import React from "react";

const Body2 = (props) => {
    return (
        <div className="flex items-center justify-center "{...props}>
          <div className="
            mt-72
            mb-113
            mobile:mt-72 mobile:mb-113
            mobileXl:mt-72 mobileXl:mb-113
            tablet:mt-[120px] tablet:mb-[86px]
            desktop:mb-[45px]
            " id="atuacao">
                <p className="
                  text-[39px] tablet:text-[60px] font-black tracking-wide text-mediumGreen text-center
                ">O QUE FAZEMOS?</p>
            </div>
           <div>
            <p  className=" text-[18px] font-light tracking-wide text-lettertext text-center leading-10
            tablet:text-[23px] mx-16 tablet:mx-32">
                {/* <span className="font-bold">E-Cred </span> e <span className="font-bold">E-Credac</span>: sistemas especialmente desenvolvidos para produtores rurais e agropecuários com o <span className="font-bold">objetivo de recuperar créditos de ICMS </span> 
                pagos na aquisição de insumos utilizados em atividades agrícolas até 5 anos anteriores (extemporâneos). Todo o processo é administrativo e realizado 
               no posto fiscal do estado de São Paulo. Com esses créditos recuperados, os produtores rurais podem investir em insumos
               e equipamentos necessários para suas atividades agrícolas, <span className="font-bold">convertendo-os em receita para seus negócios.</span> */}
               Proporcionamos um levantamento prévio dos créditos tributários sem custo. Após o levantamento e o devido alinhamento entre as partes é realizado o procedimento administrativo de recuperação de crédito de ICMS,  sendo para pessoa física o sistema E-credRural e para pessoa jurídica o  E-credAc.<br/> 
               Analisamos e desenvolvemos uma sistemática com objetivo de recuperar o ICMS pago na aquisição de insumos e óleo diesel utilizados nas atividades do produtor rural. <br/>
               Além disto realizamos todo aconselhamento jurídico e financeiro durante todo o procedimento até sua utilização do recurso, que somente a partir da utilização, é cobrado percentual de participação da recuperação realizada e utilizada.
            </p>
            </div> 
          <div className="
            mt-72
            mb-113
            mobile:mt-72 mobile:mb-113
            mobileXl:mt-72 mobileXl:mb-113
            tablet:mt-[120px] tablet:mb-[86px]
            desktop:mb-[45px]
            " id="beneficios">
                <p className="
                  text-[39px] tablet:text-[60px] font-black tracking-wide text-mediumGreen text-center
                ">QUAIS BENEFÍCIOS AO PRODUTOR?</p>
            </div>
           <div>
            <p  className=" text-[18px] font-light tracking-wide text-lettertext text-center leading-10
            tablet:text-[23px] mx-16 tablet:mx-32">
              Estes créditos são um benefício de direito do produtor rural previsto na Legislação vigente. <br/>
              Com o credito de ICMS, os produtores rurais podem adquirir insumos, equipamentos, óleo diesel, venda do credito e liquidação de impostos, convertendo em receita para atividade rural.
            </p>
            </div> 
        </div>
    )
}

export default Body2