import React, { useState } from 'react';
import RocketIcon from './rocket_icon';
import DiamondIcon from './diamond_icon';
import BinocularsIcon from './binoculars_icon';

const SideButton = ({ title, description, Icon }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={`transition-all ease-in-out duration-100 flex flex-col justify-center items-center 
                      ${isExpanded ? 'w-full tablet:w-full h-[150px] bg-mediumGreen' : 
                      'w-[312px] h-[107px] bg-lightBrown tablet:w-[640px] tablet:h-[135px] desktop:w-[500px] desktop:h-[115px]'} 
                      rounded-l-lg`}
         onClick={handleClick}>
        <div className="object-fill transition-all  duration-0 flex items-center w-full pr-[auto]">
          <div className="px-[18px] tablet:scale-[1.4] tablet:px-[35px]">
            <Icon />
          </div>
          <div className={`${isExpanded ? 'text-[13px] tablet:text-[18px]' : 'text-[29px] tablet:text-[45px]'} font-extrabold text-white `}>
            {isExpanded ? description : title}
          </div>
      </div>
    </div>
  );
};

const SideButtons = () => {
  const buttons = [
    {
      title: 'MISSÃO',
      description: 'Recuperar crédito de ICMS e auxiliar o produtor rural a reorganizar e reduzir os custos da agroprodução, poupando-o de toda a burocracia do processo.',
      icon: RocketIcon,
    },
    {
      title: 'VISÃO',
      description: 'Ser uma referência na recuperação de crédito de ICMS, fornecendo soluções inovadoras que reduzem custos e aumentam a produtividade.',
      icon: BinocularsIcon,
    },
    {
      title: 'VALORES',
      description: 'Integridade, compromisso, inovação, respeito, responsabilidade.',
      icon: DiamondIcon,
    }
  ];

  return (
    <div className="flex flex-col gap-[30px] items-end overflow-hidden 
    tablet:gap-[50px]">
      {buttons.map((button, index) => (
        <SideButton key={index} title={button.title} description={button.description} Icon={button.icon} />
      ))}
    </div>
  );
}


export default SideButtons;
