import React from 'react';

const MainContainer = ({ children }) => (
  <div className="
  overflow-hidden
  grid 
  grid-cols-4
  gap-8
  mobile:w-mobile mobile:mx-auto
  mobileXl:w-mobileXl mobileXl:auto
  tablet:grid-cols-8 tablet:mx-auto tablet:gap-16 tablet:w-tablet
  desktop:grid-cols-12 desktop:mx-0 desktop:gap-20 desktop:w-desktop
  desktopXl:grid-cols-12 desktopXl:mx-0 desktopXl:gap-20 desktopXl:w-desktopXl
  font-poppins">
    {children}
  </div>
);

export default MainContainer;
