import React from "react";

const CoinIcon = () => (
  <>
    <svg width="118" height="118" viewBox="0 0 118 118" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="59" cy="59" r="59" fill="#717a51"/>
      <path d="M59 37.373C47.0495 37.373 37.3662 47.0564 37.3662 59.0068C37.3662 70.9573 47.0495 80.6407 59 80.6407C70.9505 80.6407 80.6338 70.9573 80.6338 59.0068C80.6338 47.0564 70.9505 37.373 59 37.373ZM60.8301 68.0687V70.65C60.8301 70.9642 60.5843 71.21 60.2702 71.21H57.8664C57.5523 71.21 57.3406 70.9642 57.3406 70.65V68.1029C55.2851 67.7205 53.6121 66.7098 52.4921 65.3509C52.3146 65.105 52.3555 64.6885 52.5946 64.3402L53.9876 62.3871C54.2676 61.9364 54.5817 62.0389 54.8617 62.2096C56.3641 63.2885 57.5113 63.8144 58.7337 63.8144C59.6761 63.8144 60.3043 63.4661 60.3043 62.7013C60.3043 62.0389 59.8878 61.6906 58.7678 61.2399L56.5348 60.3316C54.3018 59.4576 52.8404 57.962 52.8404 55.5924C52.8404 52.8063 54.7934 51.0239 57.3406 50.5049V47.3705C57.3406 47.0564 57.5523 46.8105 57.8664 46.8105H60.2702C60.5843 46.8105 60.8301 47.0564 60.8301 47.3705V50.5459C62.4008 50.9283 63.7939 51.7683 64.8728 53.0179C65.0845 53.2638 64.9753 53.5438 64.8387 53.7486L63.0632 55.8383C62.8173 56.0841 62.5783 56.05 62.2642 55.8383C61.1511 54.9642 60.2087 54.4793 59.2663 54.4793C58.2215 54.4793 57.9074 55.0734 57.9074 55.5924C57.9074 56.2207 58.5015 56.6372 59.2663 56.9514L61.8476 57.962C63.9714 58.7952 65.6855 60.3316 65.6103 62.913C65.5762 65.8084 63.5207 67.5839 60.837 68.0756L60.8301 68.0687Z" fill="#FAFAFA"/>
      <path d="M59 27C41.3269 27 27 41.3269 27 59C27 76.6731 41.3269 91 59 91C76.6731 91 91 76.6731 91 59C91 41.3269 76.6799 27 59 27ZM59 83.42C45.513 83.42 34.5869 72.487 34.5869 59.0068C34.5869 45.5267 45.5198 34.5869 59 34.5869C72.4802 34.5869 83.4131 45.5198 83.4131 59C83.4131 72.4802 72.4802 83.4131 59 83.4131V83.42Z" fill="#FAFAFA"/>
    </svg>
  </>
);

export default CoinIcon;

