import React, { useState, useEffect } from 'react';

const FloatingMenu = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => setIsOpen(!isOpen);

    const closeMenu = (event) => {
        if (!event.target.closest('.menu-container') && isOpen) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', closeMenu);

        return () => {
            document.removeEventListener('click', closeMenu);
        };
    }, [isOpen]);

    const scrollToSection = (sectionId, event) => {
      event.preventDefault();
      const section = document.getElementById(sectionId);
      section.scrollIntoView({behavior: "smooth"});
    };

    return (
        <div className="fixed bottom-4 left-4 tablet:left-auto tablet:right-4 flex flex-col-reverse items-start tablet:items-end z-50 desktop:hidden menu-container">
            <button onClick={toggleMenu} className="p-2 rounded-full bg-transparent focus:outline-none scale-[0.7] tablet:scale-[1]"
            style={{ boxShadow: 'none' }}>
                {isOpen ? (
                    <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle opacity="0.8" cx="47" cy="47" r="47" fill="#8DA633"/>
                        <path d="M62.6791 60.4814C63.8507 59.3099 63.8507 57.4104 62.6791 56.2388L53.4405 47.0001L62.6791 37.7614C63.8507 36.5899 63.8507 34.6904 62.6791 33.5188L60.3958 31.2355C59.2243 30.0639 57.3248 30.0639 56.1532 31.2355L46.9145 40.4742L37.6758 31.2355C36.5043 30.0639 34.6048 30.0639 33.4332 31.2355L31.1499 33.5188C29.9783 34.6904 29.9783 36.5899 31.1499 37.7614L40.3886 47.0001L31.1499 56.2388C29.9783 57.4104 29.9783 59.3099 31.1499 60.4814L33.4332 62.7647C34.6048 63.9363 36.5043 63.9363 37.6758 62.7647L46.9145 53.5261L56.1532 62.7647C57.3248 63.9363 59.2243 63.9363 60.3958 62.7647L62.6791 60.4814Z" fill="#FAFAFA"/>
                    </svg>
                ) : (
                    <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle opacity="0.8" cx="47" cy="47" r="47" fill="#BE8D58"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M59.999 30.08C61.6559 30.08 62.999 31.4231 62.999 33.08V35.54C62.999 37.1968 61.6559 38.54 59.999 38.54L33.8301 38.54C32.1733 38.54 30.8301 37.1968 30.8301 35.54V33.08C30.8301 31.4231 32.1733 30.08 33.8301 30.08H59.999ZM59.999 55.46C61.6559 55.46 62.999 56.8031 62.999 58.46V60.92C62.999 62.5768 61.6559 63.92 59.999 63.92H33.8301C32.1733 63.92 30.8301 62.5768 30.8301 60.92V58.46C30.8301 56.8031 32.1733 55.46 33.8301 55.46L59.999 55.46ZM67.5946 45.3854C67.5946 43.7286 66.2514 42.3854 64.5946 42.3854L29.2346 42.3854C27.5777 42.3854 26.2346 43.7286 26.2346 45.3854V48.6145C26.2346 50.2714 27.5777 51.6145 29.2346 51.6145L64.5946 51.6145C66.2514 51.6145 67.5946 50.2714 67.5946 48.6145V45.3854Z" fill="#FAFAFA"/>
                    </svg>
                )}
            </button>

            {isOpen && (
                <ul style={{ borderRadius: 26 }} className="p-4 bg-white bg-opacity-90 mb-0 text-center text-lightBrown text-[18px] tablet:text-[30px]">
                    <li><a href="#QuemSomos" className="block mb-2 tablet:mb-3" onClick={(event) => scrollToSection('quemSomos', event)}>Quem somos</a></li>
                    <li><a href="#Atuação" className="block mb-2 tablet:mb-3" onClick={(event) => scrollToSection('atuacao', event)}>Atuação</a></li>
                    <li><a href="#Beneficios" className="block mb-2 tablet:mb-3" onClick={(event) => scrollToSection('beneficios', event)}>Benefícios</a></li>
                    <li><a href="#Insumos" className="block mb-2 tablet:mb-3" onClick={(event) => scrollToSection('insumos2', event)}>Insumos</a></li>
                    <li><a href="#Contato" className="block " onClick={(event) => scrollToSection('contato', event)}>Contato</a></li>
                </ul>
            )}
        </div>
    );
};

export default FloatingMenu;
