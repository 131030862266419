import React from "react";
import soilBG from "../assets/soil1.png"
import soil2BG from "../assets/soil2.png"
import MidButtons from "./mid_button";
import MidButtonsHorizontal from "./mid_button_x";
import MidButtonsHorizontalHover from "./mid_button_x_hover";

const Body3 = () => {
  return (
    <div className="">
      <div className=" items-center justify-center mt-[-160px] bg-left bg-cover bg-no-repeat h-[1669px] overflow-hidden
      tablet:mt-[50px] hidden mobile:hidden tablet:hidden tablet:h-[547px]
      desktop:flex
      " style={{backgroundImage: `url(${soil2BG})`}}>
        <div className=""><MidButtonsHorizontalHover/></div>
      </div>
      <div className=" items-center justify-center mt-[-160px] bg-left bg-cover bg-no-repeat h-[1669px] overflow-hidden
      tablet:mt-[50px] hidden mobile:hidden tablet:flex tablet:h-[547px]
      desktop:hidden
      " style={{backgroundImage: `url(${soil2BG})`}}>
        <div className=""><MidButtonsHorizontal/></div>
      </div>
      <div className="flex items-center justify-center mt-[-160px] bg-left bg-cover bg-no-repeat h-[1669px] overflow-hidden
      tablet:mt-0 tablet:hidden desktop:hidden
      " style={{backgroundImage: `url(${soilBG})`}}>
        <div className=""><MidButtons/></div>
      </div>
    </div>
  )
}

export default Body3