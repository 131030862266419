import React from "react";
import plantMan1 from "../assets/man-florist-working-green-house 1.png"
import plantMan2 from "../assets/man-florist-working-green-house 2.png"
import plantMan3 from "../assets/man-florist-working-green-house 3.png"
import HerbIcon from "./herb_icon";
import PlantBottleIcon from "./plant_bottle_icon";
import FertilizerIcon from "./fertilizer_icon";
import LimestoneIcon from "./limestone_icon";
import SaltIcon from "./salt_icon";
import OilIcon from "./oil_icon";

const Body5 = (props) => {
    return (
        <div className="flex items-center text-center justify-center"{...props}>
            <div className="bg-lightBrown h-[1100px] pt-[56px] px-16 tablet:px-32
            mobileXl:h-[1060px]
            tablet:h-[1160px]">
                <p className="text-white  mx-[16px] text-center text-[18px] font-light tracking-wide mb-[66px] 
                tablet:hidden">Agronegócio fácil com <br/>
                    <span className="font-extrabold"> aquisição de insumos:</span>
                </p>
                <p className="text-white  mx-[16px] text-center text-[18px] font-light tracking-wide mb-[66px] hidden 
                tablet:block tablet:text-[23px]">Agronegócio fácil com 
                    <span className="font-extrabold"> aquisição de insumos:</span>
                </p>
                <div className="flex flex-col gap-[37px] justify-center items-center text-[24px] font-medium mx-0
                tablet:flex-wrap tablet:flex-row tablet:flex-grow tablet:justify-left">
                    <div className="min-w-[330px] h-[58px] bg-fafafa rounded-full flex justify-start items-center
                    tablet:w-[320px] tabletL:w-[400px]">
                        <div className="px-[10px]"><HerbIcon/></div>
                        <p>Herbicidas</p>
                    </div>
                    <div className="min-w-[330px] h-[58px] bg-fafafa rounded-full flex justify-start items-center
                    tablet:w-[320px] tabletL:w-[400px]">
                        <div className="px-[10px]"><PlantBottleIcon/></div>
                        <p>Fertilizantes</p>
                    </div>
                    <div className="min-w-[330px] h-[58px] bg-fafafa rounded-full flex justify-start items-center
                    tablet:w-[320px] tabletL:w-[400px]">
                        <div className="px-[10px]"><FertilizerIcon/></div>
                        <p>Adubos</p>
                    </div>
                    <div className="min-w-[330px] h-[58px] bg-fafafa rounded-full flex justify-start items-center
                    tablet:w-[320px] tabletL:w-[400px]">
                        <div className="px-[10px]"><LimestoneIcon/></div>
                        <p>Calcário</p>
                    </div>
                    <div className="min-w-[330px] h-[58px] bg-fafafa rounded-full flex justify-start items-center
                    tablet:w-[320px] tabletL:w-[400px]">
                        <div className="px-[10px]"><SaltIcon/></div>
                        <p>Sal</p>
                    </div>
                    <div className="min-w-[330px] h-[58px] bg-fafafa rounded-full flex justify-start items-center
                    tablet:w-[320px] tabletL:w-[400px]">
                        <div className="px-[10px]"><OilIcon/></div>
                        <p>Óleo diesel</p>
                    </div>
                </div>
            </div>
                <div className="col-span-full relative right-0 mt-[-360px] z-0 flex justify-end
                mobileXl:mt-[-390px]
                tablet:mt-[-845px]
                ">
                    <img className="mobileXl:hidden z-0 
                    " src={plantMan1} alt=''/>
                    <img className="hidden mobileXl:block tablet:hidden z-0" src={plantMan2} alt=''/>
                    <img className="hidden mobileXl:hidden tablet:block z-0" src={plantMan3} alt=''/>
                </div>
        </div>
    )
}

export default Body5