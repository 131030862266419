import React from "react";
import oldMan from '../assets/old-man-1.png'
import oldMan2 from '../assets/old-man-2.png'
import oldMan3 from '../assets/old-man-3.png'
import oldManFull from '../assets/old-man-full.png'

const Slogan = (props) => {

    const scrollToSection = () => {
        const section = document.getElementById('contato');
        section.scrollIntoView({behavior: "smooth"});
    }
    return (
        <div {...props}>
            <p className="text-3D2F1D text-[30px] tracking-tight mx-16
            mobile:text-34px
            mobileXl:text-41px mobileXl:tracking-tight 
            tablet:text-68px tablet:mx-[32px] tablet:tracking-tight
            desktop:mx-0 desktop:text-[50px]
            ">Levando resutado, margem e lucro ao 
                <span className="text-F5853F"> produtor rural
                </span>
                <span className="rounded-full
                 bg-3D2F1D 
                 h-8 w-8 
                 inline-flex 
                 items-center 
                 justify-center 
                 ml-5"></span>
            </p>
            <button className="mx-16 bg-mediumGreen rounded-[17px] hover:bg-lightGreen hover:shadow-lg col-span-2 h-[56px] w-[196px] mt-[50px] relative z-10 mb-[481px] 
            mobile:col-span-2
            mobileXl:col-span-2
            tablet:w-[290px] tablet:mx-[32px]
            desktop:mx-0
            "
            onClick={scrollToSection}>
                <p className="text-white font-extrabold text-16px">Entre em contato</p>
            </button>
            <div className="col-span-full absolute right-0 top-[380px] z-0
            desktop:top-[270px]">
                <img className="mobileXl:hidden z-0
                " src={oldMan} alt=''/>
                <img className="hidden mobileXl:block tablet:hidden desktop:hidden z-0" src={oldMan2} alt=''/>
                <img className="hidden mobileXl:hidden tablet:block desktop:hidden z-0" src={oldMan3} alt=''/>
                <img className="hidden mobileXl:hidden tablet:hidden desktop:block z-0 overflow-hidden" src={oldManFull} alt=''/>
            </div>
        </div>  
    )
}

export default Slogan
