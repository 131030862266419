import React from "react";

const HeaderMenu = (props) => {

    const scrollToSection = (sectionId, event) => {
        event.preventDefault();
        const section = document.getElementById(sectionId);
        section.scrollIntoView({behavior: "smooth"});
    };

    return (
        <div {...props}>
            <header className="flex items-center justify-between pb-[169px] pt-[61px]">
                <div className="flex items-center">
                    <span className="
                    text-stone-800 text-4xl font-black leading-10 tracking-wide
                    mobile:text-stone-800 mobile:text-2xl mobile:font-black mobile:leading-10 mobile:tracking-wide
                    mobileXl:text-stone-800 mobileXl:text-2xl mobileXl:font-black mobileXl:leading-10 mobileLx:tracking-wide
                    tablet:text-stone-800 tablet:text-5xl tablet:font-black tablet:leading-10 tablet:tracking-wider
                      desktop:text-4xl
                    ">AGRO</span>
                    <span className="
                    text-stone-800 text-4xl font-medium leading-10 tracking-wide
                    mobile:text-stone-800 mobile:text-2xl mobile:font-medium mobile:leading-10 mobile:tracking-wide
                    mobileXl:text-stone-800 mobileXl:text-2xl mobileXl:font-medium mobileXl:leading-10 mobileXl:tracking-wide
                    tablet:text-stone-800 tablet:text-5xl tablet:font-medium tablet:leading-10 tablet:tracking-wider
                    desktop:text-4xl
                    ">RECUPERA</span>
                </div>
                <nav>
                    <ul className="flex space-x-4 text-lightBrown text-[19px] font-extralight">
                        <li><a href="#QuemSomos" className=" hover:underline" onClick={(event) => scrollToSection('quemSomos', event)}>Quem somos</a></li>
                        <li><a href="#Atuação" className=" hover:underline" onClick={(event) => scrollToSection('atuacao', event)}>Atuação</a></li>
                        <li><a href="#Insumos" className=" hover:underline" onClick={(event) => scrollToSection('insumos', event)}>Insumos</a></li>
                        <li><a href="#Curiosidades" className=" hover:underline" onClick={(event) => scrollToSection('curiosidades', event)}>Curiosidades</a></li>
                        <li><a href="#Contato" className=" hover:underline" onClick={(event) => scrollToSection('contato', event)}>Contato</a></li>
                    </ul>
                </nav>
            </header>
        </div>
    )
}

export default HeaderMenu