import React from "react";

const MoneyBagIcon = () => (
  <>
    <svg width="118" height="118" viewBox="0 0 118 118" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="59" cy="59" r="59" fill="#717a51"/>
      <path d="M66.6683 30.0521C65.6136 30.2462 64.3203 31.5116 63.375 31.5961C60.8602 31.242 61.375 27.5897 56.9157 29.601C55.9722 30.0341 55.1094 31.4037 54.2179 31.6751C52.3399 32.2485 49.1183 29.8095 48.3631 33.2083C47.9829 34.914 50.173 36.4903 51.0161 37.7665C51.5973 38.6472 52.6018 40.3511 52.6018 40.3511C52.6018 40.3511 55.4431 40.6513 56.5516 40.6657C59.9328 40.7501 63.4755 41.4655 66.7365 40.2415C67.4289 38.3416 68.4567 36.7797 69.3697 35.0111C70.6451 32.6511 69.9186 29.4536 66.6701 30.0503L66.6683 30.0521Z" fill="#FAFAFA"/>
      <path d="M83.3896 63.435C80.9304 54.6008 73.3572 49.0254 66.9535 42.9987C64.3723 43.653 61.653 43.6727 59.0072 43.5811C56.8314 43.5074 54.5085 43.7572 52.4708 42.8459C42.6177 51.0654 28.2678 62.1912 36.3988 76.3959C48.1317 97.9717 89.4901 90.6761 83.3896 63.435ZM61.5292 73.4482V75.8585C61.5292 76.1515 61.3014 76.3797 61.009 76.3797H58.765C58.4727 76.3797 58.2771 76.1515 58.2771 75.8585V73.4806C56.3596 73.1229 54.7991 72.1774 53.7587 70.9067C53.5955 70.6784 53.6296 70.2884 53.8556 69.9613L55.156 68.1369C55.4161 67.7127 55.7085 67.8116 55.9686 67.9734C57.3659 68.9835 58.4386 69.4724 59.5776 69.4724C60.4548 69.4724 61.0413 69.1471 61.0413 68.4299C61.0413 67.8116 60.6503 67.4845 59.6117 67.0621L57.531 66.2155C55.4502 65.4013 54.0852 64.0012 54.0852 61.785C54.0852 59.1788 55.9058 57.518 58.2789 57.0291V54.0976C58.2789 53.8046 58.4745 53.5763 58.7668 53.5763H61.0108C61.3032 53.5763 61.531 53.8046 61.531 54.0976V57.0615C62.9947 57.4191 64.2952 58.201 65.3015 59.3747C65.497 59.603 65.3983 59.8636 65.2692 60.0595L63.6118 62.0132C63.3839 62.2415 63.1561 62.2092 62.8638 62.0132C61.8234 61.199 60.9462 60.7425 60.0673 60.7425C59.0915 60.7425 58.7991 61.2961 58.7991 61.785C58.7991 62.3709 59.3516 62.7628 60.0673 63.0557L62.4727 64.0012C64.4566 64.783 66.0495 66.2155 65.9831 68.6276C65.9508 71.3309 64.0333 72.9935 61.5292 73.4482Z" fill="#FAFAFA"/>
    </svg>
  </>
);

export default MoneyBagIcon;

