import React from "react";
import logo1 from "../assets/logo1.png"
import InstagramIcon from "./instagram_icon";
import FacebookIcon from "./facebook_icon";

const Footer = (props) => {
  return (
    <div className="flex flex-col items-center text-center justify-center" {...props}>
      <div className="bg-lightGreen w-full pt-[130px] flex flex-col items-center justify-center h-auto">
        <div className="mb-[50px] "><img src={logo1} alt=''/></div>
        <div>
            <p className="text-white font-black text-[26px] mb-[110px]
            tablet:text-[40px] tablet:mb-[95px]">
                Soluções <span className="text-darkBrown">inteligentes</span> <br/>
                para o seu agronegócio.
            </p>
        </div>
        <div className="w-full flex justify-center gap-[16px] mb-[87px]">
            <a href="https://www.instagram.com/agrorecupera/" className="tablet:scale-[1.2]"><InstagramIcon/></a>
            <a href="https://www.facebook.com/agrorecupera/" className="tablet:scale-[1.2]"><FacebookIcon/></a>
        </div>
      </div>
      <div className="bg-mediumGreen w-full h-[50px] flex items-center justify-center">
        <p className="text-white">© 2023 GG's Studios</p>
      </div>
    </div>
  );
};

export default Footer;
