import React from "react";

const Body4 = (props) => {
    return (
        <div className="flex items-center justify-center "{...props} id="insumos2">
          <div className="
            mt-72
            mb-113
            mobile:mt-72 mobile:mb-113
            mobileXl:mt-72 mobileXl:mb-113
            tablet:mt-[180px] tablet:mb-[72px]">
                <p className="
                  text-[39px] mt-[-140px] font-black tracking-wide text-mediumGreen text-center
                  tablet:mt-0 tablet:text-[60px]
                ">SOLUÇÕES AGRÍCOLAS</p>
            </div>
            <div>
                <p  className=" text-[18px] font-light tracking-wide text-lettertext text-center leading-10 mb-[138px] 
                tablet:mb-[72px] tablet:text-[23px] mx-16 tablet:mx-32">
                    <span className="font-bold">Com o crédito obtido </span>, você poderá adquirir uma variedade de <span className="font-bold">insumos, equipamentos</span> e até mesmo
                    <span className="font-bold"> liquidar seus impostos. <br/></span> 
                    <span className="font-bold">Convertendo o crédito em receita </span><span>para a atividade do produtor rural.</span>
                </p>
            </div>
        </div>
    )
}

export default Body4