import React, { useState } from 'react';
import PhysicalPersonIcon from './physical_person_icon';
import LegalPersonIcon from './legal_person_icon';
import CoinIcon from './coin_icon';
import MoneyBagIcon from './money_bag_icon';

const MidButtonHorizontal = ({ title, titleDescription, description, Icon, position, isExpanded, onClick }) => {
  const getBorderClasses = (position) => {
    switch(position) {
      case 'first':
        return 'border-l-[13px] border-t-[13px] border-b-[13px]';
      case 'middle':
        return 'border-y-[13px]';
      case 'last':
        return 'border-t-[13px] border-b-[13px] border-r-[13px]';
      default:
        return '';
    }
  };

  const getRoundedClasses = (position) => {
    switch(position) {
      case 'first':
        return 'rounded-l-[26px]';
      case 'last':
        return 'rounded-r-[26px]';
      default:
        return '';
    }
  };

  return (
    <div 
      className={`transition-all ease-in-out duration-100 flex flex-col justify-center items-center 
                  ${isExpanded ? 'rounded-[26px] relative bg-mediumGreen transform scale-y-[1.5] scale-x-[1.5] desktop:scale-y-[2.0] desktop:scale-x-[2.0]' 
                  : `w-[180px] h-[180px] desktop:w-[250px] bg-lightBrown ${getBorderClasses(position)} ${getRoundedClasses(position)}`}
                  `}
      onClick={onClick}
      style={isExpanded ? {position: 'relative', zIndex: 1} : {position: 'static'}}
    >
        <div className="object-fill transition-all  duration-0 flex flex-col items-center w-full">
          <div className={`${isExpanded ? 'mb-[-30px] scale-x-[0.5] scale-y-[0.5] object-contain' : 'hidden'} font-extrabold text-white `}>
            <Icon />
          </div>
          <div className={`${isExpanded ? 'text-[13px] text-center scale-x-[0.6] scale-y-[0.6] mb-[-10px]' : 'text-[21px] text-center'} font-extrabold text-white w-full`}>
            {isExpanded ? titleDescription : title}
          </div>
          <div className={`${isExpanded ? 'text-[13px] text-center scale-x-[0.6] scale-y-[0.6] pb-[20px]' : 'hidden'} font-normal text-white w-full`}>
            {isExpanded ? description : ''}
          </div>
        </div>
    </div>
  );
};

const MidButtonsHorizontal = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const buttons = [
    {
      title: 'eCred',
      titleDescription: 'eCred',
      description: 'Produtor Pessoa física',
      icon: PhysicalPersonIcon,
    },
    {
      title: 'eCredac',
      titleDescription: 'eCredac',
      description: 'Pessoa jurídica',
      icon: LegalPersonIcon,
    },
    {
      title: 'ICMS Interestadual',
      titleDescription: 'ICMS Interestadual',
      description: 'Crédito ICMS',
      icon: CoinIcon,
    },
    {
      title: 'Planejamento Tributário',
      titleDescription: 'Planejamento',
      description: 'Tributário e Funrural',
      icon: MoneyBagIcon,
    }
  ];

  return (
    <div className="flex flex-row items-center overflow-visible">
      {buttons.map((button, index) => (
        <MidButtonHorizontal 
          key={index} 
          title={button.title}
          titleDescription={button.titleDescription}
          description={button.description} 
          Icon={button.icon} 
          position={index === 0 ? 'first' : (index === buttons.length - 1 ? 'last' : 'middle')}
          isExpanded={index === expandedIndex}
          onClick={() => setExpandedIndex(index === expandedIndex ? null : index)}
        />
      ))}
    </div>
  );
}

export default MidButtonsHorizontal;
