import React from "react";

const RocketIcon = () => (
    <>
        <svg width="67" height="67" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="33.6045" cy="33.3928" r="33.0193" fill="#FAFAFA"/>
          <path d="M25.9137 43.7854C25.5178 44.3758 24.8008 44.7356 24.3215 44.8318C23.5087 44.9926 22.7096 45.0506 22.1016 45.5441C21.9099 45.6983 21.6216 45.5896 21.5704 45.3451C21.449 44.6196 21.6604 43.8302 21.9861 43.3353C21.8455 43.3353 21.7109 43.3676 21.5829 43.4315C21.3268 43.5468 21.0393 43.3353 21.1095 43.0586C21.3209 42.2434 21.8836 41.114 22.7661 40.6257L21.3334 39.1879C21.2441 39.0982 21.1607 39.0014 21.078 38.9052C18.2823 39.5022 16.543 42.8273 15.9416 45.1C15.7748 45.7418 16.4406 46.2426 17.0413 45.9599C17.3361 45.8182 17.6618 45.7352 17.9816 45.7477C17.2331 46.897 16.7406 48.7519 17.0282 50.4533C17.1306 51.0187 17.8082 51.275 18.2567 50.9159C19.6893 49.7476 21.5507 49.6257 23.4634 49.2468C24.896 48.9516 27.1862 47.6805 27.762 45.5744C27.6018 45.4519 27.4481 45.324 27.3011 45.1764L25.9131 43.7834L25.9137 43.7854Z" fill="#55641F"/>
          <path d="M44.3518 34.7096C50.0188 28.2796 51.4423 19.0167 51.2814 16.2319C51.2643 15.9255 51.1448 15.6547 50.9498 15.459C50.7548 15.2633 50.485 15.1433 50.1797 15.1262C47.4049 14.9648 38.1747 16.394 31.7678 22.0814L29.7212 21.3322C27.4705 20.5079 24.9459 21.1774 23.3944 23.0099L19.8384 27.2088C19.3348 27.8031 19.5764 28.7184 20.3065 28.986L24.8264 30.6406C23.5724 32.6807 22.7031 34.446 22.1929 35.5794C21.837 36.3702 22.0025 37.2947 22.6138 37.9082L28.5814 43.8974C29.1927 44.5109 30.1139 44.6769 30.9018 44.3198C32.0311 43.8078 33.7901 42.9347 35.8229 41.6767L37.4715 46.2136C37.7381 46.9464 38.6501 47.1882 39.2423 46.6834L43.4261 43.1146C45.2514 41.5568 45.9184 39.0238 45.0977 36.7649L44.3512 34.7103L44.3518 34.7096ZM41.9172 29.6706C40.5017 31.0913 38.2056 31.0913 36.7893 29.6706C35.3738 28.2499 35.3738 25.9455 36.7893 24.5242C38.2056 23.1028 40.5017 23.1028 41.9172 24.5242C43.3335 25.9455 43.3335 28.2499 41.9172 29.6713V29.6706Z" fill="#55641F"/>
        </svg>
    </>
  );

export default RocketIcon;

