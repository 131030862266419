import React from "react";

const LimestoneIcon = () => (
  <>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20.0679" cy="19.8774" r="19.7661" fill="#8DA633"/>
      <g clip-path="url(#clip0_0_1)">
      <path d="M12.6464 29.6909C12.5118 29.6909 12.3726 29.6456 12.238 29.5096L10.4247 27.6964C10.2002 27.4697 10.2002 27.1071 10.4247 26.8804C10.6491 26.6538 11.0126 26.6538 11.2415 26.8804L13.0548 28.6936C13.2793 28.9203 13.2793 29.2829 13.0548 29.5096C12.9202 29.6002 12.7811 29.6909 12.6464 29.6909Z" fill="#BBCA85"/>
      <path d="M29.4196 10.5166C31.6862 12.7831 31.6862 16.4095 29.4196 18.6759L23.9797 24.1155L20.8064 20.9424L22.6197 19.1292C22.8935 18.8573 22.9833 18.54 22.9833 18.2226C22.9833 17.9053 22.8486 17.588 22.6197 17.316C22.1215 16.8174 21.3046 16.8174 20.8064 17.316L18.9931 19.1292L15.8198 15.9562L21.2597 10.5166C22.3908 9.38331 23.845 8.83936 25.3397 8.83936C26.8343 8.83936 28.284 9.38331 29.4196 10.5166Z" fill="#BBCA85"/>
      <path d="M23.9795 24.7052C23.8449 24.7052 23.7057 24.6599 23.5711 24.5239L20.3978 21.3508C20.1734 21.1242 20.1734 20.7615 20.3978 20.5349C20.6222 20.3082 20.9858 20.3082 21.2147 20.5349L23.9795 23.3L29.011 18.2684C31.0487 16.2286 31.0487 12.9195 29.011 10.925C28.0146 9.92769 26.6995 9.38374 25.3395 9.38374C23.9795 9.38374 22.6644 9.92769 21.668 10.925L16.6366 15.9566L19.4014 18.7217C19.6303 18.9483 19.6303 19.311 19.4014 19.5376C19.177 19.7643 18.8134 19.7643 18.5845 19.5376L15.4112 16.3645C15.1868 16.1379 15.1868 15.7753 15.4112 15.5486L20.8511 10.109C22.0316 8.93044 23.6609 8.25049 25.3395 8.25049C27.0182 8.25049 28.6026 8.93044 29.8279 10.109C32.3189 12.6022 32.3189 16.5912 29.8279 19.0843L24.388 24.5239C24.2533 24.6146 24.1142 24.7052 23.9795 24.7052Z" fill="#FAFAFA"/>
      <path d="M10.8329 31.5044C10.6982 31.5044 10.5591 31.4591 10.4244 31.3231L8.61113 29.5099C8.38223 29.2833 8.38223 28.9206 8.61113 28.694L20.3976 16.9082C20.7611 16.5456 21.2145 16.3643 21.7127 16.3643C22.2109 16.3643 22.6642 16.5456 23.0278 16.9082C23.3913 17.2709 23.5709 17.7242 23.5709 18.2228C23.5709 18.7214 23.3913 19.1747 23.0278 19.5374L11.2413 31.3231C11.1067 31.4138 10.9675 31.5044 10.8329 31.5044ZM9.83646 29.102L10.8329 30.0992L22.2109 18.7214C22.3455 18.5854 22.4353 18.4041 22.4353 18.2228C22.4353 18.0415 22.3455 17.8601 22.2109 17.7242C22.0762 17.5882 21.8922 17.4975 21.7127 17.4975C21.5331 17.4975 21.3491 17.5882 21.2145 17.7242L9.83646 29.102Z" fill="#FAFAFA"/>
      </g>
      <defs>
      <clipPath id="clip0_0_1">
      <rect width="23.2542" height="23.2542" fill="white" transform="translate(8.44067 8.25049)"/>
      </clipPath>
      </defs>
    </svg>
  </>
);

export default LimestoneIcon;

