import React from "react";

const HerbIcon = () => (
  <>
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20.0679" cy="20.4121" r="19.7661" fill="#8DA633"/>
      <g clip-path="url(#clip0_0_1)">
      <path d="M27.8953 26.6001C28.8001 26.6001 29.5381 27.3347 29.5381 28.2374C29.5381 29.1401 28.8045 29.8747 27.8953 29.8747C26.986 29.8747 26.2524 29.1401 26.2524 28.2374C26.2524 27.3347 26.986 26.6001 27.8953 26.6001Z" fill="white"/>
      <path d="M13.3909 11.4222C14.0586 12.0859 14.0586 13.1037 13.3909 13.7675C12.7277 14.4313 11.7042 14.4313 11.0409 13.7675C10.3776 13.1037 10.3776 12.0859 11.0409 11.4222C11.7086 10.8026 12.7277 10.8026 13.3909 11.4222Z" fill="white"/>
      <path d="M21.4206 19.6086C20.6211 20.4494 20.2214 21.5114 20.2214 22.662H20.1336C20.1336 21.5114 19.7338 20.4494 18.9344 19.6086C18.7148 19.3874 18.4907 19.2103 18.2667 19.0333C18.091 18.5466 17.9988 18.0598 17.9988 17.5288C17.9988 16.0242 18.7982 14.6524 20.0413 13.7231C21.2844 14.6082 22.0839 16.0242 22.0839 17.5288C22.0839 18.0598 21.996 18.5466 21.8159 18.9891L21.9038 19.0776C21.7281 19.2989 21.548 19.4316 21.4162 19.6086H21.4206Z" fill="#BBCA85"/>
      <path d="M25.3212 18.3651C25.5892 19.8254 25.2334 21.2857 24.2143 22.3035C23.1952 23.3656 21.7281 23.7638 20.3093 23.5426C20.2214 23.277 20.2214 22.9673 20.2214 22.6575C20.2214 21.507 20.6212 20.4449 21.4206 19.6042C21.5963 19.4272 21.7325 19.2944 21.9082 19.1616C22.8833 18.4536 24.0825 18.1881 25.3256 18.3651H25.3212Z" fill="#BBCA85"/>
      <path d="M18.9343 19.6088C19.7337 20.4496 20.1335 21.5116 20.1335 22.6622C20.1335 22.9719 20.0895 23.2817 20.0456 23.5472C18.5829 23.7685 17.1158 23.3259 16.1406 22.3081C15.1215 21.2461 14.7657 19.7858 15.0337 18.3698C16.1889 18.1927 17.3837 18.4583 18.3149 19.0778C18.5346 19.2105 18.7586 19.3875 18.9343 19.6088Z" fill="#BBCA85"/>
      <path d="M28.7825 20.5779C28.8704 20.6664 28.9582 20.7991 28.9582 20.9762C28.9582 21.1532 28.9143 21.2859 28.7825 21.3744C28.6507 21.4629 28.5629 21.5514 28.3828 21.5514C28.0709 21.5514 27.8074 21.2859 27.8074 20.9762C27.8074 20.8434 27.8513 20.6664 27.9831 20.5779C28.0709 20.4894 28.2027 20.4009 28.3828 20.4009C28.5629 20.4009 28.6507 20.4894 28.7825 20.5779Z" fill="#FAFAFA"/>
      <path d="M24.346 14.5196C24.4338 14.6081 24.5217 14.7409 24.5217 14.9179C24.5217 15.0949 24.4778 15.2276 24.346 15.3161C24.2142 15.4046 24.0781 15.4489 23.9463 15.4489C23.6344 15.4489 23.3708 15.1834 23.3708 14.8736C23.3708 14.7409 23.4148 14.5638 23.5466 14.4753C23.6344 14.3868 23.7662 14.2983 23.9463 14.2983C24.1264 14.2983 24.2142 14.3868 24.346 14.5196Z" fill="#FAFAFA"/>
      <path d="M24.346 25.5826C24.4338 25.6711 24.5217 25.8038 24.5217 25.9809C24.5217 26.1136 24.4778 26.2906 24.346 26.3791C24.2142 26.4676 24.0781 26.5119 23.9463 26.5119C23.6344 26.5119 23.3708 26.2464 23.3708 25.9366C23.3708 25.8038 23.4148 25.6268 23.5466 25.5383C23.6344 25.4498 23.7662 25.3613 23.9463 25.3613C24.1264 25.3613 24.2142 25.4498 24.346 25.5826Z" fill="#FAFAFA"/>
      <path d="M16.0047 15.6257C16.0925 15.7142 16.1804 15.847 16.1804 16.024C16.1804 16.201 16.1365 16.3338 16.0047 16.4223C15.8729 16.5108 15.7851 16.5993 15.605 16.5993C15.2931 16.5993 15.0295 16.3338 15.0295 16.024C15.0295 15.8912 15.0735 15.7142 15.2052 15.6257C15.2931 15.5372 15.4249 15.4487 15.605 15.4487C15.7851 15.4487 15.9168 15.493 16.0047 15.6257Z" fill="#FAFAFA"/>
      <path d="M13.7862 22.7903C13.874 22.8788 13.9619 23.0115 13.9619 23.1886C13.9619 23.3656 13.918 23.4983 13.7862 23.5868C13.6544 23.6753 13.5666 23.7638 13.3865 23.7638C13.0746 23.7638 12.811 23.4983 12.811 23.1886C12.811 23.0558 12.855 22.8788 12.9867 22.7903C13.1185 22.7018 13.2064 22.6133 13.3865 22.6133C13.5666 22.6133 13.6983 22.7018 13.7862 22.7903Z" fill="#FAFAFA"/>
      <path d="M19.334 24.1667C17.9152 24.1667 16.6282 23.6357 15.7409 22.7064C14.6339 21.5558 14.1859 19.9628 14.4978 18.2812C14.5417 18.0599 14.7174 17.8829 14.9853 17.8387C15.2533 17.7944 15.5168 17.7944 15.7848 17.7944C16.8478 17.7944 17.8273 18.1042 18.6663 18.6352C18.9343 18.8122 19.1978 19.0335 19.4218 19.2547C20.3091 20.1398 20.7528 21.3788 20.7528 22.7064C20.7528 23.0161 20.7089 23.3701 20.6649 23.7242C20.621 23.9454 20.4453 24.1224 20.1774 24.1667H19.334ZM15.5168 18.8565C15.429 20.0513 15.7848 21.1133 16.5359 21.9098C17.2475 22.6621 18.2227 23.0604 19.3296 23.0604H19.5492V22.6621C19.5492 21.6443 19.1934 20.6708 18.5301 20.007C18.3544 19.83 18.1743 19.653 17.9547 19.5202C17.2914 19.0335 16.4041 18.8122 15.5168 18.8565Z" fill="#FAFAFA"/>
      <path d="M21.816 19.6082C21.772 19.6082 21.6842 19.6082 21.6402 19.564C21.3723 19.4755 21.1966 19.1214 21.3284 18.8559C21.4602 18.4577 21.548 18.0152 21.548 17.5726C21.548 16.4221 21.0165 15.3158 20.0853 14.475C19.1541 15.2716 18.6226 16.3778 18.6226 17.5726C18.6226 18.0152 18.7104 18.4577 18.8422 18.8559C18.93 19.1214 18.7983 19.4755 18.4864 19.564C18.2184 19.6525 17.867 19.5197 17.7748 19.21C17.5991 18.6789 17.4629 18.1037 17.5068 17.5284C17.5068 15.8911 18.3502 14.298 19.769 13.2802C19.9447 13.1475 20.2127 13.1475 20.4323 13.2802C21.8511 14.3423 22.6945 15.9353 22.6945 17.5284C22.6945 18.1037 22.6066 18.6347 22.3826 19.21C22.2508 19.4755 22.0268 19.6082 21.8072 19.6082H21.816Z" fill="#FAFAFA"/>
      <path d="M21.0209 24.1665C20.7529 24.1665 20.4894 24.1665 20.2214 24.1222C20.0018 24.078 19.7778 23.901 19.7338 23.6797C19.6899 23.3257 19.646 23.0159 19.646 22.6619C19.646 21.3344 20.1336 20.1396 20.9769 19.2103C21.1526 19.0333 21.3327 18.8563 21.5524 18.7235C22.3957 18.104 23.4587 17.75 24.5701 17.75C24.838 17.75 25.1016 17.75 25.3695 17.7943C25.5891 17.8385 25.8132 18.0155 25.8571 18.2368C26.169 19.9183 25.6814 21.5556 24.614 22.6619C23.6828 23.6355 22.3957 24.1665 21.0209 24.1665ZM20.8012 23.0602C22.0004 23.1044 23.0634 22.7062 23.8189 21.9097C24.5745 21.1131 24.9259 20.0068 24.838 18.8563C23.8629 18.812 22.9756 19.0775 22.264 19.6086C22.1322 19.7413 21.9521 19.8741 21.8203 20.0068C21.1526 20.7149 20.8012 21.6441 20.8012 22.6619C20.7573 22.7947 20.7573 22.9274 20.8012 23.0602Z" fill="#FAFAFA"/>
      <path d="M20.0415 26.7729C19.7296 26.7729 19.4661 26.5074 19.4661 26.1977V23.5426C19.4661 23.2328 19.734 22.9673 20.0415 22.9673C20.349 22.9673 20.6169 23.2328 20.6169 23.5426V26.1977C20.6169 26.5517 20.349 26.7729 20.0415 26.7729Z" fill="#FAFAFA"/>
      <path d="M30.6012 24.4277C30.5573 24.4277 30.4694 24.4277 30.4255 24.3834C30.1136 24.2949 29.9819 23.9852 30.0697 23.6754C31.3128 19.8698 30.3377 15.7544 27.4957 12.9665C23.8586 9.3423 17.9594 8.89978 13.7865 11.9487C13.5185 12.1257 13.2111 12.0815 13.031 11.816C12.8553 11.5505 12.8992 11.2407 13.1627 11.0637C15.3854 9.4308 18.1351 8.62985 20.8849 8.80685C23.6785 8.98386 26.2965 10.2229 28.2951 12.17C29.8457 13.7188 30.9131 15.6659 31.4007 17.7899C31.8882 19.8698 31.7565 21.9938 31.0888 24.0294C31.0449 24.2949 30.8209 24.4277 30.6012 24.4277Z" fill="#FAFAFA"/>
      <path d="M20.0412 32.0389C19.7733 32.0389 19.5097 32.0389 19.2418 31.9946C16.4481 31.8176 13.8301 30.5786 11.8315 28.6315C9.84169 26.6889 8.64252 24.0338 8.46681 21.2415C8.29111 18.4979 9.08617 15.7543 10.729 13.5417C10.9047 13.2762 11.2605 13.2319 11.4845 13.4089C11.7525 13.5859 11.7964 13.94 11.6163 14.1612C8.55467 18.3209 8.99831 24.1665 12.6354 27.835C16.2724 31.4636 22.1716 31.9061 26.3446 28.8528C26.6125 28.6758 26.92 28.72 27.1001 28.9855C27.2758 29.251 27.2319 29.5608 26.9683 29.7378C24.9741 31.2424 22.5318 32.0389 20.05 32.0389H20.0412Z" fill="#FAFAFA"/>
      <path d="M12.1919 14.8251C11.6165 14.8251 11.0366 14.6038 10.6413 14.1613C10.2416 13.763 9.97363 13.1878 9.97363 12.6125C9.97363 12.0372 10.1933 11.4619 10.6413 11.0637C11.041 10.6654 11.6165 10.3999 12.1919 10.3999C12.7673 10.3999 13.3471 10.6212 13.7425 11.0637C14.1422 11.4619 14.4101 12.0372 14.4101 12.6125C14.4101 13.1878 14.1861 13.763 13.7425 14.1613C13.3427 14.6038 12.8112 14.8251 12.1919 14.8251ZM12.1919 11.5062C11.88 11.5062 11.6165 11.6389 11.3924 11.816C11.1728 12.0372 11.0806 12.3027 11.0806 12.6125C11.0806 12.9222 11.2123 13.1878 11.3924 13.409C11.6121 13.6303 11.88 13.7188 12.1919 13.7188C12.5038 13.7188 12.7673 13.586 12.9913 13.409C13.211 13.1878 13.3032 12.9222 13.3032 12.6125C13.3032 12.3027 13.1714 12.0372 12.9913 11.816C12.7717 11.5947 12.5038 11.5062 12.1919 11.5062Z" fill="#FAFAFA"/>
      <path d="M27.895 30.4506C27.3196 30.4506 26.7398 30.2293 26.3444 29.7868C25.9447 29.3885 25.6812 28.8132 25.6812 28.238C25.6812 27.6627 25.9008 27.0874 26.3444 26.6892C26.7442 26.2909 27.3196 26.0254 27.895 26.0254C28.4704 26.0254 29.0502 26.2466 29.4456 26.6892C29.8453 27.0874 30.1133 27.6627 30.1133 28.238C30.1133 28.8132 29.8936 29.3885 29.4456 29.7868C29.0459 30.2293 28.4704 30.4506 27.895 30.4506ZM27.895 27.1317C27.5831 27.1317 27.3196 27.2644 27.0956 27.4414C26.8759 27.6627 26.7837 27.9282 26.7837 28.238C26.7837 28.5477 26.9155 28.8132 27.0956 29.0345C27.3152 29.2558 27.5831 29.3443 27.895 29.3443C28.2069 29.3443 28.4704 29.2115 28.6945 29.0345C28.9141 28.8132 29.0063 28.5477 29.0063 28.238C29.0063 27.9282 28.8745 27.6627 28.6945 27.4414C28.4748 27.2644 28.2069 27.1317 27.895 27.1317Z" fill="#FAFAFA"/>
      </g>
      <defs>
      <clipPath id="clip0_0_1">
      <rect width="23.2542" height="23.2542" fill="white" transform="translate(8.44067 8.78516)"/>
      </clipPath>
      </defs>
    </svg>
  </>
);

export default HerbIcon;

