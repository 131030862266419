import React from "react";

const SaltIcon = () => (
  <>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20.0679" cy="20.2197" r="19.7661" fill="#8DA633"/>
      <g clip-path="url(#clip0_0_1)">
      <path d="M31.1601 23.7715V25.314H29.5924H25.469V23.7715H29.5924H31.1601Z" fill="white"/>
      <path d="M25.4691 23.7713V25.3139H21.3457V21.2545V19.2249V17.1952V15.1655H25.4691V23.7713Z" fill="#BBCA85"/>
      <path d="M25.4691 13.9079V15.1257H8.97559V13.9079C8.97559 13.1772 9.55123 12.6089 10.2943 12.6089H24.1464C24.8894 12.6089 25.465 13.2178 25.465 13.9079H25.4691Z" fill="#BBCA85"/>
      <path d="M21.3457 21.2544V25.3137H16.6467C16.3976 24.4207 15.6138 23.7712 14.6666 23.7712C13.7195 23.7712 12.8948 24.4207 12.6866 25.3137H9.01636V21.2544H21.3457Z" fill="#BBCA85"/>
      <path d="M16.6467 25.314C16.3977 24.421 15.6138 23.7715 14.6667 23.7715C13.7195 23.7715 12.8948 24.421 12.6866 25.314C12.6458 25.4764 12.605 25.6388 12.605 25.8418C12.605 26.9784 13.5113 27.8714 14.6667 27.8714C15.822 27.8714 16.7284 26.9784 16.7284 25.8418C16.7284 25.6388 16.6875 25.4764 16.6467 25.314Z" fill="#BBCA85"/>
      <path d="M14.6667 25.314C14.9565 25.314 15.2015 25.5575 15.2015 25.8417C15.2015 25.9635 15.1606 26.1258 15.0382 26.207C14.9157 26.2882 14.834 26.3694 14.6667 26.3694C14.4993 26.3694 14.3768 26.3288 14.2951 26.207C14.2135 26.1258 14.1318 26.0041 14.1318 25.8417C14.1318 25.7199 14.1727 25.5575 14.2951 25.4763C14.4176 25.3952 14.4993 25.314 14.6667 25.314Z" fill="#FAFAFA"/>
      <path d="M12.6457 25.8011H8.97549C8.68563 25.8011 8.44067 25.5576 8.44067 25.2734V13.9073C8.44067 12.8924 9.26535 12.0806 10.2942 12.0806H24.1463C25.1751 12.0806 25.9998 12.8924 25.9998 13.9073V23.7715C25.9998 24.0556 25.7507 24.2992 25.4649 24.2992C25.1792 24.2992 24.9301 24.0556 24.9301 23.7715V13.9073C24.9301 13.4607 24.5586 13.0954 24.1054 13.0954H10.2942C9.84099 13.0954 9.46948 13.4607 9.46948 13.9073V24.7863H12.6457C12.9356 24.7863 13.1805 25.0299 13.1805 25.314C13.1805 25.5982 12.9315 25.8011 12.6457 25.8011Z" fill="#FAFAFA"/>
      <path d="M25.469 25.801H16.6466C16.3568 25.801 16.1118 25.5575 16.1118 25.2733C16.1118 24.9892 16.3609 24.7456 16.6466 24.7456H25.469C25.7589 24.7456 26.0039 24.9892 26.0039 25.2733C26.0039 25.5981 25.7548 25.801 25.469 25.801Z" fill="#FAFAFA"/>
      <path d="M14.6666 28.3589C13.2663 28.3589 12.0701 27.2223 12.0701 25.8015C12.0701 25.5986 12.1109 25.355 12.1517 25.152C12.4416 24.0154 13.4704 23.2441 14.6666 23.2441C15.8628 23.2441 16.8507 24.0154 17.1814 25.152C17.2223 25.355 17.2631 25.558 17.2631 25.8015C17.2223 27.2223 16.0669 28.3589 14.6666 28.3589ZM14.6666 24.2996C13.9644 24.2996 13.3479 24.7867 13.1805 25.4362C13.1397 25.558 13.1397 25.6797 13.1397 25.8015C13.1397 26.654 13.8419 27.3441 14.7074 27.3441C15.5729 27.3441 16.2751 26.654 16.2751 25.8015C16.2751 25.6797 16.2751 25.558 16.2343 25.4362C15.9852 24.7461 15.3688 24.2996 14.6666 24.2996Z" fill="#FAFAFA"/>
      <path d="M31.16 25.8011H25.4689C25.179 25.8011 24.9341 25.5575 24.9341 25.2734V23.7308C24.9341 23.4467 25.1831 23.2031 25.4689 23.2031H31.16C31.4498 23.2031 31.6948 23.4467 31.6948 23.7308V25.2734C31.654 25.5981 31.4049 25.8011 31.16 25.8011ZM26.0078 24.7863H30.6252V24.2586H26.0078V24.7863Z" fill="#FAFAFA"/>
      <path d="M29.5924 28.3584C29.3026 28.3584 29.0576 28.1149 29.0576 27.8307V25.2733C29.0576 24.9892 29.3067 24.7456 29.5924 24.7456C29.8782 24.7456 30.1272 24.9892 30.1272 25.2733V27.8307C30.1272 28.1149 29.8782 28.3584 29.5924 28.3584Z" fill="#FAFAFA"/>
      <path d="M29.5924 24.2993C29.3026 24.2993 29.0576 24.0557 29.0576 23.7716V22.7567C29.0576 22.4726 29.3067 22.229 29.5924 22.229C29.8782 22.229 30.1272 22.4726 30.1272 22.7567V23.7716C30.1272 24.0557 29.8782 24.2993 29.5924 24.2993Z" fill="#FAFAFA"/>
      <path d="M25.469 15.6531H8.97549C8.68563 15.6531 8.44067 15.4095 8.44067 15.1254C8.44067 14.8412 8.68971 14.5977 8.97549 14.5977H25.469C25.7589 14.5977 26.0038 14.8412 26.0038 15.1254C26.0038 15.4501 25.7548 15.6531 25.469 15.6531Z" fill="#FAFAFA"/>
      <path d="M21.3459 25.8014C21.056 25.8014 20.811 25.5579 20.811 25.2737V15.1254C20.811 14.8412 21.0601 14.5977 21.3459 14.5977C21.6316 14.5977 21.8807 14.8412 21.8807 15.1254V25.2737C21.8807 25.5985 21.6316 25.8014 21.3459 25.8014Z" fill="#FAFAFA"/>
      <path d="M21.3456 21.742H8.97549C8.68563 21.742 8.44067 21.4984 8.44067 21.2142C8.44067 20.9301 8.68971 20.6865 8.97549 20.6865H21.3456C21.6355 20.6865 21.8804 20.9301 21.8804 21.2142C21.8804 21.4984 21.6314 21.742 21.3456 21.742Z" fill="#FAFAFA"/>
      <path d="M21.3456 19.7122H8.97549C8.68563 19.7122 8.44067 19.4686 8.44067 19.1845C8.44067 18.9003 8.68971 18.6567 8.97549 18.6567H21.3456C21.6355 18.6567 21.8804 18.9003 21.8804 19.1845C21.8804 19.4686 21.6314 19.7122 21.3456 19.7122Z" fill="#FAFAFA"/>
      <path d="M21.3456 17.6824H8.97549C8.68563 17.6824 8.44067 17.4388 8.44067 17.1547C8.44067 16.8705 8.68971 16.627 8.97549 16.627H21.3456C21.6355 16.627 21.8804 16.8705 21.8804 17.1547C21.8804 17.4388 21.6314 17.6824 21.3456 17.6824Z" fill="#FAFAFA"/>
      <path d="M18.255 21.7421C17.9652 21.7421 17.7202 21.4985 17.7202 21.2144V15.1254C17.7202 14.8412 17.9693 14.5977 18.255 14.5977C18.5408 14.5977 18.7898 14.8412 18.7898 15.1254V21.2144C18.7898 21.5391 18.5408 21.7421 18.255 21.7421Z" fill="#FAFAFA"/>
      <path d="M15.1605 21.7421C14.8707 21.7421 14.6257 21.4985 14.6257 21.2144V15.1254C14.6257 14.8412 14.8748 14.5977 15.1605 14.5977C15.4463 14.5977 15.6954 14.8412 15.6954 15.1254V21.2144C15.6954 21.5391 15.4463 21.7421 15.1605 21.7421Z" fill="#FAFAFA"/>
      <path d="M12.0702 21.7421C11.7804 21.7421 11.5354 21.4985 11.5354 21.2144V15.1254C11.5354 14.8412 11.7844 14.5977 12.0702 14.5977C12.356 14.5977 12.605 14.8412 12.605 15.1254V21.2144C12.605 21.5391 12.356 21.7421 12.0702 21.7421Z" fill="#FAFAFA"/>
      </g>
      <defs>
      <clipPath id="clip0_0_1">
      <rect width="23.2542" height="16.278" fill="white" transform="translate(8.44067 12.0806)"/>
      </clipPath>
      </defs>
    </svg>
  </>
);

export default SaltIcon;

