import React from "react";

const Logo = (props) => {
    return (
        <div {...props}>
            <div className="
            mt-72
            mb-72
            mobile:mt-72 mobile:mb-[90px]
            mobileXl:mt-72 mobileXl:mb-[90px]
            tablet:mt-57 tablet:mb-139">
                <span className="
                text-stone-800 text-2xl font-black leading-10 tracking-wide
                mobile:text-stone-800 mobile:text-2xl mobile:font-black mobile:leading-10 mobile:tracking-wide
                mobileXl:text-stone-800 mobileXl:text-2xl mobileXl:font-black mobileXl:leading-10 mobileLx:tracking-wide
                tablet:text-stone-800 tablet:text-5xl tablet:font-black tablet:leading-10 tablet:tracking-wider
                ">AGRO</span>
                <span className="
                text-stone-800 text-2xl font-medium leading-10 tracking-wide
                mobile:text-stone-800 mobile:text-2xl mobile:font-medium mobile:leading-10 mobile:tracking-wide
                mobileXl:text-stone-800 mobileXl:text-2xl mobileXl:font-medium mobileXl:leading-10 mobileXl:tracking-wide
                tablet:text-stone-800 tablet:text-5xl tablet:font-medium tablet:leading-10 tablet:tracking-wider
                ">RECUPERA</span>
            </div>
        </div>
    )
}

export default Logo