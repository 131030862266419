import React from "react";

const BinocularsIcon = () => (
    <>
      <svg width="67" height="67" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="33.6045" cy="33.3927" r="33.0193" fill="#FAFAFA"/>
      <path d="M16.0306 37.9214C16.4957 35.2594 18.8768 28.8988 20.5676 24.5751C21.1604 23.0594 22.3569 21.9311 23.7986 21.3577C23.8858 21.1324 23.9742 20.9043 24.0557 20.697C24.5585 19.4167 25.9066 18.6664 27.2764 18.9001L27.7792 18.9857C29.149 19.2195 30.1643 20.3725 30.2036 21.7452C30.2133 22.0809 30.2224 22.4409 30.231 22.8155C31.0957 23.7584 31.6356 24.9818 31.7097 26.3224C32.278 26.0228 32.9165 25.8369 33.6045 25.8369C34.2926 25.8369 34.9304 26.0228 35.4988 26.3224C35.5729 24.9824 36.1127 23.7589 36.9775 22.8155C36.986 22.4409 36.9952 22.0809 37.0043 21.7452C37.0436 20.372 38.0594 19.2195 39.4293 18.9857L39.932 18.9001C41.3019 18.6664 42.65 19.4161 43.1528 20.697C43.2343 20.9048 43.3232 21.133 43.4099 21.3577C44.851 21.9311 46.0481 23.0594 46.6409 24.5751C48.3322 28.8988 50.7133 35.2594 51.1779 37.9214C51.9138 42.1353 49.0539 46.1413 44.7894 46.8685C40.5961 47.5838 36.6155 44.8649 35.7821 40.7637C35.1442 41.1467 34.4043 41.3799 33.6045 41.3799C32.8047 41.3799 32.0643 41.1467 31.4269 40.7631C30.5935 44.8649 26.6123 47.5833 22.4196 46.8685C18.1551 46.1413 15.2946 42.1353 16.0311 37.9214H16.0306ZM43.4566 44.0617C46.1524 44.0617 48.3374 41.9021 48.3374 39.2389C48.3374 36.5757 46.1518 34.4161 43.4566 34.4161C40.7615 34.4161 38.5759 36.5752 38.5759 39.2389C38.5759 41.9026 40.7609 44.0617 43.4566 44.0617ZM33.6045 39.1032C34.6597 39.1032 35.5182 38.2549 35.5182 37.2122C35.5182 36.1696 34.6597 35.3208 33.6045 35.3208C32.5494 35.3208 31.6909 36.1696 31.6909 37.2122C31.6909 38.2549 32.5494 39.1032 33.6045 39.1032ZM23.7518 44.0617C26.4476 44.0617 28.6326 41.9021 28.6326 39.2389C28.6326 36.5757 26.447 34.4161 23.7518 34.4161C21.0567 34.4161 18.8711 36.5752 18.8711 39.2389C18.8711 41.9026 21.0561 44.0617 23.7518 44.0617Z" fill="#55641F"/>
      </svg>
    </> 
  );

export default BinocularsIcon;

