import React, { useState } from 'react';
import PhysicalPersonIcon from './physical_person_icon';
import LegalPersonIcon from './legal_person_icon';
import CoinIcon from './coin_icon';
import MoneyBagIcon from './money_bag_icon';

const BorderFrame = ({ position, children, isHovered }) => {
  const getBorderClasses = (position) => {
    switch(position) {
      case 'first':
        return 'border-l-[13px] border-t-[13px] border-b-[13px]';
      case 'middle':
        return 'border-y-[13px]';
      case 'last':
        return 'border-t-[13px] border-b-[13px] border-r-[13px]';
      default:
        return '';
    }
  };

  const getRoundedClasses = (position) => {
    if (isHovered) {
      switch(position) {
        case 'first':
          return ' rounded-bl-[26px] rounded-tl-[26px]'; // arredondamento somente em baixo
        case 'last':
          return ' rounded-br-[26px] rounded-tr-[26px]'; // arredondamento somente em baixo
        default:
          return '';
      }
    } else {
      switch(position) {
        case 'first':
          return 'rounded-l-[26px]'; // arredondamento à esquerda
        case 'last':
          return 'rounded-r-[26px]'; // arredondamento à direita
        default:
          return '';
      }
    }
  };

  return (
    <div 
      className={`w-[180px] h-[180px] desktop:w-[250px] ${isHovered ? 'bg-mediumGreen' : 'bg-lightBrown'} ${getBorderClasses(position)} ${getRoundedClasses(position)} overflow-visible relative`}
    >
      {children}
    </div>
  );
};

const MidButtonHorizontalHover = ({ title, titleDescription, description, Icon, position, isExpanded, onMouseEnter, onMouseLeave }) => {
  const isHovered = isExpanded;
  return (
    <div 
      onMouseEnter={onMouseEnter} 
      onMouseLeave={onMouseLeave}
      className="relative"
    >
      <BorderFrame position={position} isHovered={isHovered}>
        <div 
          className={`transition-all ease-in-out duration-100 flex flex-col justify-center items-center 
                      ${isExpanded ? 'relative bg-mediumGreen transform scale-y-[200%] h-[6.0rem] desktop:h-[8.0rem] rounded-t-[25px] rounded-bl-0' 
                      : 'w-full h-full'}`
                      }
          style={isExpanded ? {zIndex: 1, transformOrigin: 'bottom'} : {}}
        >
          <div className="object-fill transition-all duration-0 flex flex-col items-center w-full">
            <div className={`${isExpanded ? 'mb-[-30px] object-contain scale-y-[50%]' : 'hidden'} font-extrabold text-white `}>
              <Icon />
            </div>
            <div className={`${isExpanded ? 'text-[20px] text-center mb-[-10px] scale-y-[50%]' : 'text-[23px] text-center'} font-extrabold text-white w-full`}>
              {isExpanded ? titleDescription : title}
            </div>
            <div className={`${isExpanded ? 'text-[18px] text-center pb-[20px] scale-y-[50%]' : 'hidden'} font-normal text-white w-full`}>
              {isExpanded ? description : ''}
            </div>
          </div>
        </div>
      </BorderFrame>
    </div>
  );
};

const MidButtonsHorizontalHover = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const buttons = [
    {
      title: 'eCred',
      titleDescription: 'eCred',
      description: 'Produtor Pessoa física',
      icon: PhysicalPersonIcon,
    },
    {
      title: 'eCredac',
      titleDescription: 'eCredac',
      description: 'Pessoa jurídica',
      icon: LegalPersonIcon,
    },
    {
      title: 'ICMS Interestadual',
      titleDescription: 'ICMS Interestadual',
      description: 'Crédito ICMS',
      icon: CoinIcon,
    },
    {
      title: 'Planejamento Tributário',
      titleDescription: 'Planejamento',
      description: 'Tributário e Funrural',
      icon: MoneyBagIcon,
    }
  ];

  return (
    <div className="flex flex-row items-center overflow-visible">
      {buttons.map((button, index) => (
        <MidButtonHorizontalHover 
          key={index} 
          title={button.title}
          titleDescription={button.titleDescription}
          description={button.description} 
          Icon={button.icon} 
          position={index === 0 ? 'first' : (index === buttons.length - 1 ? 'last' : 'middle')}
          isExpanded={index === expandedIndex}
          onMouseEnter={() => setExpandedIndex(index)}
          onMouseLeave={() => setExpandedIndex(null)}
        />
      ))}
    </div>
  );
}

export default MidButtonsHorizontalHover;
