import React from "react";

const FacebookIcon = () => (
  <>
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M35.8619 27.767L37.1575 19.2354H29.0525V13.7015C29.0525 11.3668 30.1826 9.08981 33.8128 9.08981H37.5V1.82646C37.5 1.82646 34.1553 1.25 30.9589 1.25C24.2808 1.25 19.9201 5.33708 19.9201 12.733V19.2354H12.5V27.767H19.9201V48.3926C21.4098 48.6289 22.9338 48.75 24.4863 48.75C26.0388 48.75 27.5628 48.6289 29.0525 48.3926V27.767H35.8619Z" fill="#FAFAFA"/>
    </svg>
  </>
);

export default FacebookIcon;

