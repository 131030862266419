import "./App.css";
import MainContainer from "./components/main_container";
import Logo from "./components/logo";
import Body from "./components/body";
import Body2 from "./components/body2";
import Body3 from "./components/body3";
import Body4 from "./components/body4";
import Body5 from "./components/body5";
import Body7 from "./components/body7";
import Body8 from "./components/body8";
import Slogan from "./components/slogan";
import Footer from "./components/footer";
import EartBottomBg from "./components/bg_earth_bottom";
import Body45 from "./components/body_4_5";
import HeaderMenu from "./components/header_menu";
import ScrollToTopButton from "./components/floating_button";
import FloatingMenu from "./components/floating_menu_button";

function App() {
  return (
    <div className=" font-poppins desktop:mx-auto desktop:max-w-[1080px]">
      <FloatingMenu/>
      <ScrollToTopButton/>
      <HeaderMenu className="hidden desktop:block"/>
      <MainContainer>
        <Logo
          className="col-span-4 text-center
          tablet:col-span-8
          desktop:hidden
          "
        />
        <Slogan
          className="font-black col-span-4
          mobile:col-span-4
          mobileXl:col-span-4
          tablet:col-span-8"
        />
      </MainContainer>
      <Body
        className="col-span-6 text-center
      tablet:col-span-8
      desktop:col-span-12
      "
      />
      <Body2
        className="col-span-4
        mobile:col-span-4
        mobileXl:col-span-4
        tablet:col-span-8
        desktop:col-span-12"
      />
      <Body3
        className="col-span-4
      mobile:col-span-4
      mobileXl:col-span-4
      tablet:col-span-8
      desktop:col-span-12"
      />
      <Body45 className="
      hidden desktop:block
      "/>
      <Body4
        className=" col-span-4
        mobile:col-span-4
        mobileXl:col-span-4
        tablet:col-span-8
        desktop:hidden"
      />
      <Body5
        className="col-span-4
      mobile:col-span-4
      mobileXl:col-span-4
      tablet:col-span-8
      desktop:col-span-12
      desktop:hidden"
      />
      {/* <Body6
        className="col-span-4
        mobile:col-span-4
        mobileXl:col-span-4
        tablet:col-span-8
        desktop:col-span-12"
      /> */}
      <Body7
        className="col-span-4
        mobile:col-span-4
        mobileXl:col-span-4
        tablet:col-span-8
        desktop:col-span-12"
      />
      <EartBottomBg
        className="col-span-4
      mobile:col-span-4
      mobileXl:col-span-4
      tablet:col-span-8
      desktop:col-span-12"
      ></EartBottomBg>
      <Body8
        className="col-span-4
      mobile:col-span-4
      mobileXl:col-span-4
      tablet:col-span-8
      desktop:col-span-12"
      />
      <Footer />
    </div>
  );
}

export default App;
