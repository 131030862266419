import React from "react";
import earthBottom1 from"../assets/earth-bottom-1.png"
import earthBottom3 from"../assets/earth-bottom-3.png"

const EartBottomBg = () => {
    return(
      <div className="col-span-full relative w-full z-0 flex justify-center mt-[-120px]
      tablet:mt-[-220px]">
        <img
          className="mobileXl:hidden z-0 
                    "
          src={earthBottom1}
          alt=""
        />
        <img
          className="hidden mobileXl:block tablet:hidden z-0"
          src={earthBottom1}
          alt=""
        />
        <img
          className="hidden mobileXl:hidden tablet:block z-0"
          src={earthBottom3}
          alt=""
        />
      </div>
    );
};

export default EartBottomBg;