import React from "react";

const OilIcon = () => (
  <>
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20.0679" cy="20.562" r="19.7661" fill="#8DA633"/>
      <path d="M28.3918 28.1476L24.5179 16.4874L26.1838 13.1748H12.8367L14.5026 16.4874L10.5692 28.1476C10.0338 29.7377 11.2369 31.3939 12.9028 31.3939H26.0582C27.7307 31.3939 28.9272 29.7377 28.3984 28.1476H28.3918Z" fill="#BBCA85"/>
      <path d="M26.1903 9.8623H12.8366C12.3672 9.8623 11.9706 10.0611 11.6334 10.3261C11.3029 10.5911 11.1641 11.0548 11.1641 11.5186C11.1641 12.4461 11.8979 13.1749 12.83 13.1749H26.1837C26.653 13.1749 27.0497 12.9761 27.3868 12.7111C27.724 12.3799 27.8562 11.9823 27.8562 11.5186C27.8562 10.5911 27.0563 9.8623 26.1903 9.8623Z" fill="#BBCA85"/>
      <path d="M24.5179 23.0464C24.9938 23.0464 25.3839 23.4306 25.3839 23.9077C25.3839 24.3847 24.9938 24.7689 24.5179 24.7689C24.0419 24.7689 23.6519 24.3847 23.6519 23.9077C23.6519 23.4306 24.0419 23.0464 24.5179 23.0464Z" fill="#FAFAFA"/>
      <path d="M22.8518 26.3589C23.3344 26.3589 23.7179 26.7431 23.7179 27.2202C23.7179 27.6972 23.3278 28.0814 22.8518 28.0814C22.3759 28.0814 21.9858 27.6972 21.9858 27.2202C21.9858 26.7431 22.3759 26.3589 22.8518 26.3589Z" fill="#FAFAFA"/>
      <path d="M18.6475 26.3589C19.1301 26.3589 19.5135 26.7431 19.5135 27.2202C19.5135 27.6972 19.1235 28.0814 18.6475 28.0814C18.1715 28.0814 17.7815 27.6972 17.7815 27.2202C17.7815 26.7431 18.1715 26.3589 18.6475 26.3589Z" fill="#FAFAFA"/>
      <path d="M14.5025 25.6303C14.9808 25.6303 15.3685 25.2446 15.3685 24.769C15.3685 24.2933 14.9808 23.9077 14.5025 23.9077C14.0242 23.9077 13.6365 24.2933 13.6365 24.769C13.6365 25.2446 14.0242 25.6303 14.5025 25.6303Z" fill="#FAFAFA"/>
      <path d="M26.0582 32.1891H12.9029C11.8319 32.1891 10.8337 31.6591 10.2321 30.7979C9.63055 29.9366 9.43223 28.8766 9.76276 27.8165L13.5705 16.4876L12.0369 13.5062C11.8385 13.1087 11.9707 12.5787 12.4401 12.38C12.8434 12.1812 13.3722 12.3137 13.5772 12.7775L15.2431 16.09C15.3092 16.2888 15.3753 16.4876 15.3092 16.7526L11.3692 28.4128C11.1709 28.9428 11.3031 29.4728 11.5675 29.8703C11.898 30.2678 12.3674 30.5329 12.9029 30.5329H26.0582C26.5937 30.5329 27.0565 30.2678 27.3936 29.8703C27.7307 29.4728 27.7969 28.8766 27.5919 28.4128L23.6519 16.7526C23.5858 16.5538 23.5858 16.2888 23.718 16.09L25.3905 12.7775C25.5889 12.38 26.1243 12.1812 26.5276 12.38C26.9308 12.5787 27.1292 13.1087 26.9308 13.5062L25.3972 16.4876L29.2049 27.8165C29.5355 28.8103 29.4033 29.9366 28.7356 30.7979C28.134 31.7254 27.1358 32.1891 26.0648 32.1891H26.0582Z" fill="#FAFAFA"/>
      <path d="M26.1903 13.9702H12.8366C11.4351 13.9702 10.2981 12.8439 10.2981 11.4526C10.2981 10.7901 10.5625 10.1938 11.0319 9.66382C11.5013 9.20006 12.1689 8.93506 12.8366 8.93506H26.1903C27.5918 8.93506 28.7288 10.0613 28.7288 11.4526C28.7288 12.1151 28.4644 12.7114 27.995 13.2414C27.5257 13.7714 26.858 13.9702 26.1903 13.9702ZM12.8366 10.6576C12.6383 10.6576 12.4334 10.7238 12.235 10.9226C12.1028 11.0551 11.9706 11.2539 11.9706 11.5189C11.9706 11.9826 12.3739 12.3801 12.8366 12.3801H26.1903C26.3886 12.3801 26.5936 12.3139 26.7919 12.1151C26.9241 11.9826 27.0563 11.7839 27.0563 11.5189C27.0563 11.0551 26.6531 10.6576 26.1903 10.6576H12.8366Z" fill="#FAFAFA"/>
    </svg>
  </>
);

export default OilIcon;

